import React, { Component } from 'react';
import Modal from 'react-modal';
import { Button } from './Button';
import { LINK, TEXT, IMAGE, HTML } from './constants';
import { _handleNestedOrderedList } from '../helpers';
import uuid from 'uuid/v4';

import { DetailsList } from './SortableDetails';
import arrayMove from 'array-move';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30em'
  }
};

Modal.setAppElement('#root');

export default class EditResourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.modalIsOpen,
      closeModal: this.props.closeModal,
      onSave: this.props.onSave,
      resource: this.props.resource || null,
      numDetails: this.props.resource ? this.props.resource.details.length : 0,
      detailsArray: this.props.resource ? this.props.resource.details : [],
      imageUris: this.props.images || {},
      imageDict: {},
      details: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
    this._handleImageUpload = this._handleImageUpload.bind(this);
    this._handleImageReplacement = this._handleImageReplacement.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalIsOpen) {
      this.setState({ modalIsOpen: true });
    } else {
      this.setState({ modalIsOpen: false });
    }
    if (nextProps.resource) {
      let detailsArrayNew = nextProps.resource.details.slice();
      nextProps.resource.details.map((detail, index) => {
        if (detail.images) {
          detailsArrayNew[index].type = IMAGE;
        } else if (detail.links) {
          detailsArrayNew[index].type = LINK;
        } else if (detail.html) {
          detailsArrayNew[index].type = HTML;
        } else {
          detailsArrayNew[index].type = TEXT;
        }
        return 0;
      })
      this.setState({ resource: nextProps.resource, detailsArray: detailsArrayNew, numDetails: nextProps.resource.details.length });
    }
    if (nextProps.images) {
      this.setState({ imageUris: nextProps.images })
    }
  }

  handleSubmit(event) {
  	event.preventDefault();
    if (this.validate()) {
      let resourceNameEl = document.getElementById("resource-name");
      let resourceName = resourceNameEl.value;
      if (!resourceName || !this.state.detailsArray.length) {
        console.log(resourceName, this.state.detailsArray);
        window.alert("Missing information.");
        return;
      }
      let details = [];
      this.state.detailsArray.forEach((detail, index) => {
        let detailNew = Object.assign({}, detail);
        if (detailNew.links) {
          if (!detailNew.links.length) {
            window.alert("Missing url.");
            return;
          }
          detailNew.links[0]['id'] = uuid();
        } else if (detailNew.images) {
          if (!detailNew.images.length) {
            window.alert("Missing image.");
            return;
          }
          delete detailNew.images[0]['__typename'];
          detailNew.images[0]['id'] = uuid();
        }
        detailNew['index'] = index;
        delete detailNew['type'];
        details.push(detailNew);
      });
      let resourceDetails = details.map((detail, index) => {
        let html = detail.html;
        if (html) {
          html = _handleNestedOrderedList(html);
        }
        return {
          id: uuid(),
          name: detail.displayName ? detail.displayName.split(" ").join("-").toLowerCase() : "other",
          displayName: detail.displayName || null,
          value: detail.value || null,
          images: detail.images || null,
          links: detail.links || null,
          html: html || null,
          index: index
        }
      })
      let resourceObject = {
        name: resourceName,
        id: this.state.resource.id,
        updatedAt: new Date().toISOString(),
        details: resourceDetails
      }
      this.state.onSave(resourceObject);
    }
	}

  validate() {
    let valid = true;
    this.state.detailsArray.forEach((detail, index) => {
      if (detail.type === HTML && detail.html.trim() === "") {
        window.alert("Empty editor.");
        valid = false;
        return;
      }
    })
    return valid;
    // TODO
	}

  renderErrors() {
    // TODO
  }

  _closeModal = () => {
    this.setState({details: [], numDetails: 0, detailTypeArr: [], images: [], imageIndex: 0, imageDict: {}});
    this.state.closeModal();
  }

  _handleImageReplacement(file, index) {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index].images[0].uri = file[0].name;
    this.setState({detailsArray: detailsArrayNew});
  }

  _handleImageUpload = (index, file) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index].images = [{ uri: file[0].name.replace('.jpeg', '.jpg') }]
    this.setState({detailsArray: detailsArrayNew});
  }

  _onChangeText = (index, key, value) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index][key] = value;
    this.setState({detailsArray: detailsArrayNew});
  }

  _onChangeLink = (index, key, value) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index].links = [{ url: value }];
    this.setState({detailsArray: detailsArrayNew});
  }

  _onChangeCaption = (index, value) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index].images[0].caption = value;
    this.setState({detailsArray: detailsArrayNew});
  }

  _shouldCancelStart = (e) => {
    for (let i = 0; i <= e.path.length; i++) {
      if (e.path[i] && typeof(e.path[i].className) === "string" && e.path[i].className.indexOf("clickable") !== -1) {
        return true;
      }
    }
    return false;
  }

  _onSortEnd = ({ oldIndex, newIndex }) => {
    let divs = document.getElementsByClassName("grab");
    for (let i = 0, max = divs.length; i < max; i++) {
      divs[i].classList.remove("grabbing");
    }

    this.setState(({ detailsArray }) => ({
      detailsArray: arrayMove(detailsArray, oldIndex, newIndex)
    }));
  };

  _onSortStart = () => {
    let divs = document.getElementsByClassName("grab");
    for (let i = 0, max = divs.length; i < max; i++) {
      divs[i].classList.add("grabbing");
    }
  }

  _renderDetails() {
    return (
      <DetailsList key="details-list"
        detailsArray={this.state.detailsArray}
        numDetails={this.state.detailsArray.length}
        _handleImageUpload={this._handleImageUpload.bind(this)}
        imageUris={this.state.imageUris}
        removeItem={this._removeItem}
        onSortEnd={this._onSortEnd}
        onSortStart={this._onSortStart}
        _onChangeText={this._onChangeText}
        _onChangeLink={this._onChangeLink}
        _onChangeCaption={this._onChangeCaption}
        shouldCancelStart={this._shouldCancelStart}
      />
    );
  }

  _removeItem = (index) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew.splice(index,1);
    this.setState({ detailsArray: detailsArrayNew });
  }

  _addDetail = (type) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    switch (type) {
      case TEXT:
        detailsArrayNew.push({
          type: HTML,
          html: ''
        });
        break;
      case IMAGE:
        detailsArrayNew.push({
          type: IMAGE,
          images: []
        });
        break;
      default:
        return;
    }
    this.setState({ detailsArray: detailsArrayNew });
  }

  render() {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this._closeModal}
        style={customStyles}
        contentLabel="Edit Resource Modal"
      >
        <div className="new-resource-container">
          <h2>Edit Resource</h2>
          {this.state.resource ?
            (
              <div>
                <div className="new-resource-form">
                  <div>
                    <div>
                      <input type="text" id="resource-name" name="resourceName" defaultValue={this.state.resource.name} />
                    </div>
                  </div>
                  {this._renderDetails()}
                  <div className="buttons">
                    <Button text="+Text" className="button-lg" onClick={() => this._addDetail(TEXT)}/>
                    <Button text="+Image" className="button-lg" onClick={() => this._addDetail(IMAGE)}/>
                  </div>
                  <hr />
                  <div className="button-container">
                    <Button type="submit" text="Submit" onClick={this.handleSubmit}/>
                  </div>
                </div>
              </div>
            ) : null
          }
        </div>
      </Modal>
    )
  }
}
