import React, { Component } from 'react';
import { Button } from './Button';

// AWS Amplify modular import
import Auth from '@aws-amplify/auth'

export default class CustomSignOut extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {}
  }

  render() {
    return (
        <Button onClick={() => Auth.signOut()} text="Sign Out"/>
    );
}
}
