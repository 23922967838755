import React from 'react';
import { Button } from './Button';
import { SignIn } from 'aws-amplify-react';

export default class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.state = {
      username: '',
      password: ''
    }
  }

  componentDidMount() {
  }

  handleUsernameChange(event) {
    this.inputs['username'] = event.target.value;
  }

  handlePasswordChange(event) {
    this.inputs['password'] = event.target.value;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.username);
    super.signIn();
  }

  showComponent(theme) {
    return (
      <div className="App signin-container">
        <h1>Sign In</h1>
        <form className="signin-form">
          <input
            type="text"
            name="username"
            placeholder='Your Username'
            onChange={this.handleUsernameChange}
            autoComplete="off"
          />
          <input
            type="password"
            name="password"
            placeholder='Your Password'
            onChange={this.handlePasswordChange}
            autoComplete="off"
          />
        </form>
        <Button
          text="Sign In"
          onClick={() => super.signIn()}
        />
      </div>
    )
  }
}
