export const _handleNestedOrderedList = (html) => {
  let searchIndex = 0;
  while (searchIndex < html.length && html.indexOf("<ol>", searchIndex) !== -1) {
    let olIndex = html.indexOf("<ol>", searchIndex);
    // check for nested ordered list(s)
    let olCloseIndex = html.indexOf("</ol>", olIndex);
    let nextOlOpenIndex = html.indexOf("<ol>", olIndex+3);
    if (nextOlOpenIndex === -1) {
      break;
    }
    if (nextOlOpenIndex < olCloseIndex) {
      // nested ol
      let newHtml = html.slice(0,nextOlOpenIndex) + '<ol type="a">' + html.slice(nextOlOpenIndex+4);
      html = newHtml;
      searchIndex = olCloseIndex + 8;
    } else {
      searchIndex = nextOlOpenIndex + 1;
    }
  }
  return html;
}
