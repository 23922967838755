import React, { Component } from 'react';
import Modal from 'react-modal';
import { Button } from './Button';
import { IMAGE, HTML } from './constants';
import uuid from 'uuid/v4';

import { DetailsList } from './SortableDetails';
import arrayMove from 'array-move';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30em'
  }
};

Modal.setAppElement('#root');

export default class NewResourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.modalIsOpen,
      closeModal: this.props.closeModal,
      onCreate: this.props.onCreate,
      detailsArray: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalIsOpen) {
      this.setState({ modalIsOpen: true });
    } else {
      this.setState({ modalIsOpen: false });
    }
  }

  handleSubmit(event) {
  	event.preventDefault();
    if (this.validate()) {
      let resourceName = document.getElementById("resource-name").value;
      if (!resourceName || !this.state.detailsArray.length) {
        window.alert("Missing information.");
        return;
      }
      let details = [];
      this.state.detailsArray.forEach((detail, index) => {
        let detailNew = Object.assign({}, detail);
        if (detailNew.links) {
          detailNew.links[0]['id'] = uuid();
        } else if (detailNew.images) {
          detailNew.images[0]['id'] = uuid();
        }
        detailNew['index'] = index;
        delete detailNew['type'];
        details.push(detailNew);
      });
      this.state.onCreate({name: resourceName, details: details});
    }
	}

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  _handleImageUpload = (index, file) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index].images = [{ uri: file[0].name.replace('.jpeg', '.jpg') }]
    this.setState({detailsArray: detailsArrayNew});
  }

  validate() {
    let valid = true;
    this.state.detailsArray.forEach((detail, index) => {
      if (detail.type === HTML && detail.html.trim() === "") {
        window.alert("Empty editor.");
        valid = false;
        return;
      }
    })
    return valid;
    // TODO
	}

  renderErrors() {
    // TODO
  }

  _removeItem = (index) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew.splice(index,1);
    this.setState({ detailsArray: detailsArrayNew });
  }

  _onSortEnd = ({ oldIndex, newIndex }) => {
    let divs = document.getElementsByClassName("grab");
    for (let i = 0, max = divs.length; i < max; i++) {
      divs[i].classList.remove("grabbing");
    }

    this.setState(({ detailsArray }) => ({
      detailsArray: arrayMove(detailsArray, oldIndex, newIndex)
    }));
  };

  _closeModal = () => {
    this.setState({detailsArray: []});
    this.state.closeModal();
  }

  _onChangeText = (index, key, value) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index][key] = value;
    this.setState({detailsArray: detailsArrayNew});
  }

  _onChangeLink = (index, key, value) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index].links = [{ url: value }];
    this.setState({detailsArray: detailsArrayNew});
  }

  _onChangeCaption = (index, value) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    detailsArrayNew[index].images[0].caption = value;
    this.setState({detailsArray: detailsArrayNew});
  }

  _shouldCancelStart = (e) => {
    for (let i = 0; i <= e.path.length; i++) {
      if (e.path[i] && typeof(e.path[i].className) === "string" && e.path[i].className.indexOf("clickable") !== -1) {
        return true;
      }
    }
    return false;
  }

  _onSortStart = () => {
    let divs = document.getElementsByClassName("grab");
    for (let i = 0, max = divs.length; i < max; i++) {
      divs[i].classList.add("grabbing");
    }
  }

  _renderDetails() {
    return (
      <DetailsList key="details-list"
        detailsArray={this.state.detailsArray}
        numDetails={this.state.detailsArray.length}
        _handleImageUpload={this._handleImageUpload.bind(this)}
        removeItem={this._removeItem}
        onSortEnd={this._onSortEnd}
        onSortStart={this._onSortStart}
        _onChangeText={this._onChangeText}
        _onChangeLink={this._onChangeLink}
        _onChangeCaption={this._onChangeCaption}
        shouldCancelStart={this._shouldCancelStart}
      />
    );
  }

  _addDetail = (type) => {
    let detailsArrayNew = this.state.detailsArray.slice();
    switch (type) {
      case HTML:
        detailsArrayNew.push({
          type: HTML,
          html: ""
        });
        break;
      case IMAGE:
        detailsArrayNew.push({
          type: IMAGE,
          images: []
        });
        break;
      default:
        return;
    }
    this.setState({ detailsArray: detailsArrayNew });
  }

  render() {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this._closeModal}
        style={customStyles}
        contentLabel="New Resource Modal"
      >
        <div className="new-resource-container">
          <h2>Create New Resource</h2>
          <div>
            <div className="new-resource-form">
              <div>
                <div className="new-resource-div-inner">
                  <input type="text" id="resource-name" name="resourceName" placeholder="Resource Name" />
                </div>
              </div>
              {this._renderDetails()}
              <div className="buttons new-resource-div-inner">
                <Button text="+Text" className="button-lg" onClick={() => this._addDetail(HTML)}/>
                <Button text="+Image" className="button-lg" onClick={() => this._addDetail(IMAGE)}/>
              </div>
              <hr />
              <div className="button-container new-resource-div-inner">
                <Button text="Submit" onClick={this.handleSubmit}/>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
