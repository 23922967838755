import React from 'react';
import { Storage } from 'aws-amplify';

export default class S3ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files || [],
      message: "",
      imagePreviewUrl: ""
    }
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  async onChange(e) {
    const files = Array.from(e.target.files);
    const success = files.length > 1 ? "Uploaded the following files:" : "Uploaded the following file:";
    const error = "Could not upload files";
    try {
      let result = await Promise.all(files.map((file) => {
        let contentType = 'image/png';
        if (file.name.indexOf('.jpeg') !== -1 || file.name.indexOf('.jpg') !== -1) {
          contentType = 'image/jpg';
        }
        let fileName = file.name.replace('.jpeg', '.jpg');
        Storage.put(fileName, file, {
          contentType: contentType
        });
        return 0;
        })
      )
      console.log(result);
      this._mounted && this.setState({files: files, message: success}, () => {this.props.onUpload ? this.props.onUpload(this.props.index, files) : console.log("no onUpload function");})
    } catch(err) {
      console.log(err);
      this._mounted && this.setState({message: error});
    }
  }

  _renderFiles = (file, index) => {
    let reader = new FileReader();
    let imagePreviewUrl = "";

    reader.onloadend = () => {
      this._mounted && this.setState({imagePreviewUrl: reader.result});
    }

    if (file.size) {
      reader.readAsDataURL(file);
    } else {
      imagePreviewUrl = this.props.imageUris[file.name];
    }

    return (
      <div key={index}>
        <p className="p-sm">{file.name}</p>
        <img src={imagePreviewUrl || this.state.imagePreviewUrl} width={100}/>
      </div>
    )
  }

  render() {
    return (
      <div>
        <h2 className={this.props.headerStyle}>{this.props.header}</h2>
        <div className="upload-btn-wrapper">
          <button className="btn">{this.props.files ? "Replace file" : this.props.multiple ? 'Upload files' : 'Upload file'}</button>
          <input
            type="file" accept={this.props.accept || 'image/png,image/jpg'}
            onChange={(e) => this.onChange(e)}
            multiple={this.props.multiple}
          />
        </div>
        <p className="file-message">{this.state.message}</p>
        <div className="files-list">
          {this.state.files.map(this._renderFiles.bind(this))}
        </div>
      </div>
    )
  }
}
