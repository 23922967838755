import React from 'react';
import { Button } from './Button';
import S3ImageUpload from './S3ImageUpload';
import { LINK, TEXT, IMAGE, HTML } from './constants';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Editor from './Editor';

const SortableDetail = SortableElement(({
  index,
  type,
  detail,
  numDetails,
  _handleImageUpload,
  imageUris,
  removeItem,
  _onChangeText,
  _onChangeLink,
  _onChangeCaption
  }) => {
    const divider = <div className="divider-horizontal divider-editing"></div>;
    return (
      <div key={index}>
        <div className="grab subtle-distinct">
        {
          (type === TEXT) ?
          (
            <div className="clickable text-detail detail-display-name">
              <div>
                <input index={index} k={'displayName'} placeholder={'Title'} value={detail.displayName || ''} onChange={(e) => _onChangeText(index, 'displayName', e.target.value)}/>
              </div>
              <div className="detail-value">
                <input index={index} k={'value'} placeholder={'Text'} value={detail.value || ''} onChange={(e) => _onChangeText(index, 'value', e.target.value)}/>
              </div>
            </div>
          ) : (type === IMAGE) ?
          (
            <div className="clickable" id="resource-s3-upload">
              <S3ImageUpload imageUris={imageUris} files={detail.images[0] ? [{name: detail.images[0].uri}] : null} headerStyle="header-sm" header="Upload Image" onUpload={_handleImageUpload} index={index} multiple={false}/>
              <input index={index} className="caption-input" placeholder={'Caption'} value={detail.images[0] ? detail.images[0].caption ? detail.images[0].caption : '' : ''} onChange={(e) => _onChangeCaption(index, e.target.value)}/>
            </div>
          ) : (type === LINK) ?
          (
            <div>
              <div className="clickable link-input">
                <input index={index} spellCheck="false" value={detail.links[0] ? detail.links[0].url : ''} placeholder={'Link'} onChange={(e) => _onChangeLink(index, undefined, e.target.value)}/>
              </div>
            </div>
          ) : (type === HTML) ?
          (
            <div className="detail-item">
              <Editor html={detail.html} onChangeHTML={_onChangeText.bind(this, index)} />
            </div>
          )
          : null
        }
        <Button key={'button'+index} text="-" className="clickable button-sm button-padded" onClick={() => {
          if (numDetails > 0) {
            removeItem(index)
          }
        }}/>
      </div>
      {
        index < numDetails - 1 ?
        (
          divider
        ) : null
      }
    </div>
  )
});

export const DetailsList = SortableContainer(({
  detailsArray,
  numDetails,
  _handleImageUpload,
  imageUris,
  removeItem,
  _onChangeText,
  _onChangeLink,
  _onChangeCaption
}) => {
  return (
    <ul>
      {detailsArray.map((detail, index) => (
        <SortableDetail
          key={`item-${index}`}
          index={index}
          type={detailsArray[index].type}
          numDetails={numDetails}
          _handleImageUpload={_handleImageUpload}
          imageUris={imageUris}
          removeItem={removeItem}
          _onChangeText={_onChangeText}
          _onChangeLink={_onChangeLink}
          _onChangeCaption={_onChangeCaption}
          detail={detail}
        />
      ))}
    </ul>
  );
});
