import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import uuid from 'uuid/v4';

export default class UsersUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      message: "",
      secondaryMessage: ""
    }
  }

  // TODO:
  // - Create row in user DB here, using autogen code in mutations
  // - Create lambda function that will see the create row in user DB and call AdminCreateUser
  // - some sort of alert if user creation did not succeed
  /*
  get over error 2019-05-18T21:34:00.909Z	b10adab4-ee1f-41c4-9cc9-c252e2d497be	INFO	puPEqD!3
2019-05-18T21:34:01.730Z	b10adab4-ee1f-41c4-9cc9-c252e2d497be	INFO	{ AccessDeniedException: User: arn:aws:sts::230821354707:assumed-role/ResidencyAppLambda/createUser is not authorized to perform: cognito-idp:AdminCreateUser on resource: arn:aws:cognito-idp:us-east-1:230821354707:userpool/us-east-1_Nt7lKK4U7
  */

  componentWillReceiveProps(nextProps) {
    if (nextProps.files) {
      this.setState({files: nextProps.files});
    }
  }

  _onLoad = async (evt) => {
    let phones = [];
    let used = [];
    try {
      const users = await API.graphql(graphqlOperation(queries.listUsers));
      phones = users.data.listUsers.items.map((user, index) => {
        return user.phoneNumber;
      });
      used = users.data.listUsers.items.map((user, index) => {
        return user.username;
      })
    } catch (err) {
      console.log("Could not fetch current users list");
      console.log(err);
    }
    try {
      let fileString = evt.target.result;
      let users = fileString.split("\n").slice(1);
      let repeatPhones = [];
      let result = await Promise.all(users.map(async (userString) => {
          if (userString.trim() !== "") {
            let unique = false;
            let user = userString.split(",");
            let color = user[3].trim();
            let username;
            while (!unique) {
              let digit1 = Math.floor(Math.random() * 10);
              let digit2 = Math.floor(Math.random() * 10);
              let digit3 = Math.floor(Math.random() * 10);
              username = color + digit1 + digit2 + digit3;
              unique = !used.includes(username);
            }
            used.push(username);

            let phoneNumber = user[2].trim();
            let givenName = user[0].trim();
            let familyName = user[1].trim();

            if (phones.indexOf(phoneNumber) !== -1) {
              console.log(`User with phone number ${phoneNumber} already exists`);
              repeatPhones.push(phoneNumber);
              return;
            }

            let id = uuid();
            let userObject = {
              id: id,
              username: username,
              phoneNumber: phoneNumber,
              givenName: givenName,
              familyName: familyName
            }
            await API.graphql(graphqlOperation(mutations.createUser, { input: userObject }));
          }
        })
      )
      console.log(result);
      if (repeatPhones.length) {
        this.setState({secondaryMessage: `Users were not created because phone numbers already exist: ${repeatPhones.join(', ')}.`})
      }
    } catch(err) {
      console.log(err);
    }
  }

  async onChange(e) {
    const files = Array.from(e.target.files);
    const success = "Uploaded the following file: " + files[0].name;
    const error = "Could not upload files";
    try {
      const fr = new FileReader();
      fr.onload = this._onLoad;
      fr.readAsText(files[0]);
      this.setState({message: success});
    } catch(err) {
      console.log(err);
      this.setState({message: error});
    }
  }

  _renderFiles = (file, index) => {
    return (
      <div key={index}>
        <p className="p-sm">{file.name}</p>
      </div>
    )
  }

  render() {
    return (
      <div>
        <h2 className={this.props.headerStyle}>Upload Users</h2>
        <div className="upload-btn-wrapper">
          <button className="btn">Upload file</button>
          <input
            type="file" accept={".csv"}
            onChange={(e) => this.onChange(e)}
            multiple={false}
          />
        </div>
        <p className="hint">Formatted as: First Name,Last Name,Phone Number (formatted as +15550123456),Color Frame</p>
        <p className="file-message">{this.state.message}</p>
        {
          this.state.secondaryMessage ?
          (
            <p className="file-message secondary">{this.state.secondaryMessage}</p>
          ) : null
        }
        <div className="files-list">
          {this.state.files.map(this._renderFiles.bind(this))}
        </div>
      </div>
    )
  }
}
