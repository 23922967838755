import React, { Component } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '30em',
    backgroundColor: '#efefef'
  }
};

Modal.setAppElement('#root');

export default class EnlargedImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.modalIsOpen,
      closeModal: this.props.closeModal,
      imageUri: this.props.imageUri
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalIsOpen) {
      this.setState({ modalIsOpen: true });
    } else {
      this.setState({ modalIsOpen: false });
    }
    if (nextProps.imageUri) {
      this.setState({imageUri: nextProps.imageUri});
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.state.closeModal}
        style={customStyles}
        contentLabel="Enlarged Image Modal"
      >
        <div className="enlarged-image-container">
          <img src={this.state.imageUri} width={300} alt="enlarged"/>
        </div>
      </Modal>
    )
  }
}
