import React, { Component } from 'react';
import './App.css';
import Loader from 'react-loader-spinner';
import aws_config from './config/aws-configs';
import { Authenticator, SignIn, ForgotPassword } from 'aws-amplify-react';
import Amplify, { Storage, API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';
import EnlargedImageModal from './components/EnlargedImageModal';
import NewResourceModal from './components/NewResourceModal';
import EditResourceModal from './components/EditResourceModal';
import CustomSignIn from './components/CustomSignIn';
import CustomSignOut from './components/CustomSignOut';
import CustomConfirmSignIn from './components/CustomConfirmSignIn';
import S3ImageUpload from './components/S3ImageUpload';
import UsersUpload from './components/UsersUpload';
import { Button } from './components/Button';
import uuid from 'uuid/v4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

Amplify.configure(aws_config);
Storage.configure({ level: 'public' });

const RESOURCE_ORDER_FILE = `resource_order_${aws_config.env}.json`;

const SortableResource = SortableElement(({index, resource, renderDetails, deleteResource, editResource}) => {
  return (
    <div key={index} className="resource-container">
      <span className="edit x" onClick={
        () => {
          if (window.confirm('Are you sure you wish to delete this item?')) {
            deleteResource(resource);
          } else {
            console.log("cancelled")
          }}
        }>
        x
      </span>
      <span className="edit" onClick={() => editResource(resource)}>
        <FontAwesomeIcon icon={faPencilAlt}/>
      </span>
      <span className="inline grab">
        <h1 className="resource-name">{resource.name}</h1>
      </span>
      {renderDetails(resource)}
    </div>
  )
});

const ResourceList = SortableContainer(({ resources, renderDetails, deleteResource, editResource }) => {
  return (
    <ul>
      {resources.map((resource, index) => (
        <SortableResource key={`item-${index}`} index={index} resource={resource} renderDetails={renderDetails} deleteResource={deleteResource} editResource={editResource} />
      ))}
    </ul>
  );
});

class App extends Component {
  uploadFile = (evt) => {
    const file = evt.target.files[0];
    const name = file.name;

    Storage.put(name, file).then(() => {
      this.setState({ file: name });
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      loadingResources: true,
      resourceModalIsOpen: false,
      editModalIsOpen: false,
      loading: false,
      signedIn: false,
      images: {},
      imageIndex: 0,
      enlargedImageUri: '',
      enlargedImageModalIsOpen: false,
      user: null,
      resourceOrder: {}
    }
    this._deleteResource = this._deleteResource.bind(this);
    this._loadImages = this._loadImages.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.signedIn === false && nextProps.authState === "signedIn") {
      this.setState({signedIn: true}, () => {
        this.componentDidMount();
      })
    }
  }

  _loadImages(detail) {
    detail.images.map(async (image, index) => {
      const src = await Storage.get(image.uri);
      if (src) {
        let newImages = Object.assign({}, this.state.images);
        newImages[image.uri] = src;
        this.setState({images: newImages});
      }
    })
  }

  _onSortEnd = ({ oldIndex, newIndex }) => {
    let divs = document.getElementsByClassName("grab");
    for (let i = 0, max = divs.length; i < max; i++) {
      divs[i].classList.remove("grabbing");
    }

    this.setState(({ resources }) => ({
      resources: arrayMove(resources, oldIndex, newIndex),
    }), () => {
      const orderMap = {};
      this.state.resources.forEach((resource, index) => {
        orderMap[resource.id] = index;
      })
      Storage.put(RESOURCE_ORDER_FILE, JSON.stringify(orderMap));
    });
  };

  _onSortStart = () => {
    let divs = document.getElementsByClassName("grab");
    for (let i = 0, max = divs.length; i < max; i++) {
      divs[i].classList.add("grabbing");
    }
  }

  async componentDidMount() {
    if (this.props.authState !== "signedIn" && this.props.authState !== "loading") return;
    this.setState({ loading: true })
    await Auth.currentAuthenticatedUser()
    .then(user => {
      this.setState({user: user, signedIn: true});
      try {
        let groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        if (groups.indexOf('Admins') !== -1) {
          console.log("Admin")
        } else {
          Auth.signOut(); // user is not Admin, get them outta here
        }
      } catch (err) {
        Auth.signOut();
      }
    })
    .catch(err => {
      console.log("error getting authenticated user")
      console.log(err);
    })
    if (!this.state.user) return;
    try {
      let resourceOrder;
      Storage.get(RESOURCE_ORDER_FILE, { download: true }).then(async (result) => {
        resourceOrder = JSON.parse(result.Body.toString());
        const resources = await API.graphql(graphqlOperation(queries.listResources, {limit: 50}));
        this.setState({ loadingResources: false });
        if (resources) {
          console.log(resourceOrder);
          console.log(resources);

          const _sortResources = (r1, r2) => {
            if (!resourceOrder[r1.id] && !resourceOrder[r2.id]) {
              return Date.parse(r2.createdAt) - Date.parse(r1.createdAt);
            } else if (!resourceOrder[r1.id]) {
              return -1;
            } else if (!resourceOrder[r2.id]) {
              return 1;
            } else {
              return resourceOrder[r1.id] - resourceOrder[r2.id];
            }
          }

          let resourceList = resources.data.listResources.items.slice().sort(_sortResources).sort(_sortResources);
          resourceList.map((resource, index) => {
            resource.details.map((detail, index) => {
              if (detail.images) {
                this._loadImages(detail);
              }
              return 0;
            })
            return 0;
          })
          this.setState({resources: resourceList, loading: false});
        }
      });
    } catch (err) {
      console.log('error listing resource... ')
      console.log(err);
    }
    try {
      const subscription = API.graphql(graphqlOperation(subscriptions.onCreateResource)).subscribe({
        next: (eventData) => {
          const newResource = eventData.value.data.onCreateResource;
          let resources = this.state.resources.slice();
          resources.map((resource, index) => {
            resource.details.map((detail, index) => {
              if (detail.images) {
                this._loadImages(detail);
              }
              return 0;
            })
            return 0;
          })
          resources.unshift(newResource);
          this.setState({resources: resources});
        }
      });
      console.log(subscription);
    } catch (err) {
      console.log('error subscribing... ')
      console.log(err);
    }
    try {
      const subscription = API.graphql(graphqlOperation(subscriptions.onDeleteResource)).subscribe({
        next: (eventData) => {
          const deletedResource = eventData.value.data.onDeleteResource;
          let resources = this.state.resources.slice();
          for (let i = 0; i < resources.length; i++){
            if (resources[i].id === deletedResource.id) {
              resources.splice(i, 1);
            }
          }
          this.setState({resources: resources});
        }
      });
      console.log(subscription);
    } catch (err) {
      console.log('error subscribing... ')
      console.log(err);
    }
    try {
      const subscription = API.graphql(graphqlOperation(subscriptions.onUpdateResource)).subscribe({
        next: (eventData) => {
          const updatedResource = eventData.value.data.onUpdateResource;
          updatedResource.details.map((detail, index) => {
            if (detail.images) {
              this._loadImages(detail);
            }
            return 0;
          })
          let resources = this.state.resources.slice();
          for (let i = 0; i < resources.length; i++){
            if (resources[i].id === updatedResource.id) {
              resources.splice(i, 1);
              resources.splice(i, 0, updatedResource);
            }
          }
          this.setState({resources: resources});
        }
      });
      console.log(subscription);
    } catch (err) {
      console.log('error subscribing... ')
      console.log(err);
    }
  }

  _enlargeImage = (imageUri) => {
    this.setState({enlargedImageUri: imageUri, enlargedImageModalIsOpen: true});
  }

  toggleDetails = (resourceId) => {
    let div = document.getElementById("details-" + resourceId);
    if (div.style.display === "none") {
      div.style.display = "block";
    } else {
      div.style.display = "none";
    }
  }

  _hideAllDetails = () => {
    let divs = document.getElementsByClassName("detail-container");
    for (let i = 0, max = divs.length; i < max; i++) {
      divs[i].style.display = "none";
    }
  }

  _renderDetails = (resource) => {
    const divider = <div className="divider-horizontal detail-divider"></div>;
    const numDetails = resource.details.length;
    return (
      <div>
        <p id="detail-toggle" className="edit" onClick={this.toggleDetails.bind(this, resource.id)}>View/Hide Details</p>
        <div className="detail-container non-pointer" id={"details-" + resource.id} style={{display: 'none'}}>
        {
          resource.details.map((detail, index) => {
            if (detail.links) {
              let link = detail.links[0].url;
              if (link.indexOf('//') === -1) {
                link = '//' + link;
              }
              return (
                <div key={index}>
                  <a target="_blank" rel="noopener noreferrer" className="clickable link-text" href={link}>{detail.links[0].url}</a>
                  {index < numDetails - 1 ? divider : null}
                </div>
              )
            } else if (detail.images) {
              return (
                <div key={index} onClick={() => {this._enlargeImage(this.state.images[detail.images[0].uri]);}} className="clickable detail-image">
                  <img src={this.state.images[detail.images[0].uri]} width={100} alt={detail.images[0].uri}/>
                  <p className="caption">{detail.images[0].caption}</p>
                  {index < numDetails - 1 ? divider : null}
                </div>
              )
            } else if (detail.html) {
              return (
                <div key={index}>
                  <div key={index} className="detail-html" dangerouslySetInnerHTML={{ __html: detail.html }}></div>
                  {index < numDetails - 1 ? divider : null}
                </div>
              )
            } else {
              return (
                <div key={index}>
                  <p className="detail-title">{detail.displayName}</p>
                  <p className="detail-text">{detail.value}</p>
                  {index < numDetails - 1 ? divider : null}
                </div>
              )
            }
          })
        }
        </div>
      </div>
    )
  }

  _deleteResource = async (resource) => {
    console.log("deleting resource");
    try {
      await API.graphql(graphqlOperation(mutations.deleteResource, { input: {id: resource.id} }));
    } catch (err) {
      console.log('error deleting detail or resource... ');
      console.log(err);
    }
  }

  _createResource = async (resource) => {
    console.log("creating resource");
    this.setState({ loading: true });
    let resourceId = uuid();
    try {
      let resourceObject = {
        id: resourceId,
        name: resource.name,
        createdAt: new Date().toISOString(),
        active: true,
        details: resource.details.map((detail, index) => {
          return {
            id: uuid(),
            name: detail.name ? detail.name.split(" ").join("-").toLowerCase() : "other",
            displayName: detail.displayName || null,
            value: detail.value || null,
            images: detail.images || null,
            links: detail.links || null,
            html: detail.html || null,
            index: index
          }
        })
      }
      await API.graphql(graphqlOperation(mutations.createResource, { input: resourceObject }));
      this.setState({ loading: false, resourceModalIsOpen: false });
    } catch (err) {
      console.log('error creating detail or resource... ');
      console.log(err);
    }
  }

  _editResourceModal = (resource) => {
    this.setState({resourceToEdit: resource, editModalIsOpen: true});
  }

  _editResource = async (resource) => {
    try {
      let resourceObject = {
        id: resource.id,
        name: resource.name,
        updatedAt: resource.updatedAt,
        details: resource.details
      }
      await API.graphql(graphqlOperation(mutations.updateResource, { input: resourceObject }));
      this.setState({ loading: false, editModalIsOpen: false });
    } catch (err) {
      console.log('error updating detail or resource... ');
      console.log(err);
    }
  }

  closeModal = () => {
    this.setState({resourceModalIsOpen: false});
  }

  closeEditModal = () => {
    this.setState({editModalIsOpen: false});
  }

  closeImageModal = () => {
    this.setState({enlargedImageModalIsOpen: false});
  }

  download = async (filename) => {
    try {
      const users = await API.graphql(graphqlOperation(queries.listUsers));
      const list = users.data.listUsers.items;
      const outputList = [];
      list.forEach((user, index) => {
        outputList.push(`${user.username},${user.givenName},${user.familyName},${user.phoneNumber}`)
      })
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(outputList.join('\n')));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } catch(err) {
      console.log("error downloading users: ")
      console.log(err);
    }
  }

  _shouldCancelStart = (e) => {
    for (let i = 0; i <= e.path.length; i++) {
      if (e.path[i] && typeof(e.path[i].className) === "string" && e.path[i].className.indexOf("grab") !== -1) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { user } = this.state;
    let groups = user ? user.signInUserSession.accessToken.payload['cognito:groups'] : null;
    if (this.props.authState === "signedIn" && (!groups || groups.indexOf('Admins') === -1)) {
      return (
        <div></div>
      )
    } else if (this.props.authState === "signedIn") {
      return (
        (this.state.loading) ?
        (
          <div className="App Loader">
            <Loader
             type="Hearts"
             color="#ff6b83"
             height="100"
             width="100"
            />
          </div>
        ) :
        (
          <div className="App flexbox">
            <div className="nav">
              <Button className="button" onClick={() => {this.setState({resourceModalIsOpen: true})}} text="New Resource"/>
              <CustomSignOut className="button"/>
            </div>
            <div className="main">
              <div className="resources-container">
                <h1>Current Resources</h1>
                <div className="resources-list">
                  <ResourceList
                    resources={
                      this.state.resources.slice()
                    }
                    renderDetails={this._renderDetails}
                    deleteResource={this._deleteResource}
                    onSortEnd={this._onSortEnd}
                    onSortStart={this._onSortStart}
                    updateBeforeSortStart={this._hideAllDetails}
                    editResource={this._editResourceModal}
                    shouldCancelStart={this._shouldCancelStart}
                  />
                </div>
              </div>
              <EnlargedImageModal modalIsOpen={this.state.enlargedImageModalIsOpen} closeModal={this.closeImageModal} imageUri={this.state.enlargedImageUri}/>
              <NewResourceModal modalIsOpen={this.state.resourceModalIsOpen} closeModal={this.closeModal} onCreate={this._createResource}/>
              <EditResourceModal modalIsOpen={this.state.editModalIsOpen} closeModal={this.closeEditModal} onSave={this._editResource} images={this.state.images} resource={this.state.resourceToEdit}/>
              <div className="upload-container">
                <div className="qr-container">
                  <S3ImageUpload header="Upload QR Codes" multiple={true} accept={'image/jpg'}/>
                </div>
                <div className="divider-horizontal"></div>
                <div className="user-container">
                  <UsersUpload />
                </div>
                <div className="divider-horizontal"></div>
                <div className="user-container">
                  <div>
                    <h2>Download Users List</h2>
                    <div className="upload-btn-wrapper">
                      <button id="dwn-btn" onClick={() => {
                        let date = new Date().toLocaleDateString();
                        let filename = `users-${date}.txt`;
                        console.log("download");
                        this.download(filename);
                      }} className="btn">Download file</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      );
    } else if (this.props.authState === "loading") {
      return (
        <div className="App Loader">
          <Loader
           type="Hearts"
           color="#ff6b83"
           height="100"
           width="100"
          />
        </div>
      )
    } else {
      return null;
    }
  }
}

class AppWithAuth extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loggedIn: false
    }
    this.handleAuthStateChange = this.handleAuthStateChange.bind(this)
  }

  handleAuthStateChange(state) {
    if (state === 'signedIn') {
        /* Do something when the user has signed-in */
      console.log("signed in");
      this.setState({loggedIn: true})
    }
  }

  render() {
    return (
      <Authenticator hide={[SignIn]} onStateChange={this.handleAuthStateChange} hideDefault={true} amplifyConfig={aws_config}>
        <CustomSignIn/>
        <CustomConfirmSignIn/>
        <ForgotPassword/>
        <App loggedIn={this.state.loggedIn}/>
      </Authenticator>
    );
  }
}

// export default withAuthenticator(App, true);
export default AppWithAuth;
