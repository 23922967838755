import React from 'react';
import { Button } from './Button';
import { ConfirmSignIn } from 'aws-amplify-react';

export default class CustomConfirmSignIn extends ConfirmSignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['confirmSignIn'];
    this.confirm = this.confirm.bind(this);
    this.checkContact = this.checkContact.bind(this);
    this.state = {
        mfaType: 'SMS'
    };
  }

  componentDidMount() {
  }

  showComponent(theme) {
    return (
      <div className="App signin-container">
        <h1>Confirm Sign In</h1>
        <form className="signin-form">
          <input
            className="code-input"
            type="text"
            name="code"
            theme={theme}
            key="code"
            autoComplete="off"
            placeholder='XXXXXX'
            onChange={this.handleInputChange}
          />
        </form>
        <Button
          text="Confirm"
          onClick={() => super.confirm()}
        />
        <br />
        <Button
          text="Go Back"
          onClick={() => this.changeState('signIn')}
        />
      </div>
    )
  }
}
