import React from 'react';

import { getDefaultKeyBinding, Editor as DraftEditor, RichUtils, EditorState, KeyBindingUtil, CompositeDecorator } from 'draft-js';
import NestedRichTextEditorUtil from 'draft-js/lib/NestedRichTextEditorUtil';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';

const { hasCommandModifier } = KeyBindingUtil;
const BOLD = 'BOLD';
const ITALIC = 'ITALIC';
const UNDERLINE = 'UNDERLINE';
const LINK = 'LINK';
const UNORDERED_LIST = 'unordered-list-item';
const ORDERED_LIST = 'ordered-list-item';
const HEADER_ONE = 'header-one';
const HEADER_TWO = 'header-two';
const HEADER_THREE = 'header-three';

/*
DESIRABLES:
- Nested lists
- Hyperlink text (link with text "here" links to "https://www.etsy.com")
*/

function customConvertFromHTML() {
  return convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'span' && node.style.color === 'blue') {
          return currentStyle.add('BLUE');
      } else {
          return currentStyle;
      }
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'a') {
        return createEntity(
          'LINK',
          'MUTABLE',
          {url: node.href}
        )
      }
    }/*,
    textToEntity: (text, createEntity) => {
      const result = [];
      text.replace(/\@(\w+)/g, (match, name, offset) => {
        const entityKey = createEntity(
          'AT-MENTION',
          'IMMUTABLE',
          {name}
        );
        result.push({
          entity: entityKey,
          offset,
          length: match.length,
          result: match
        });
      });
      return result;
    },
    htmlToBlock: (nodeName, node) => {
      if (nodeName === 'blockquote') {
        return {
            type: 'blockquote',
            data: {}
        };
      }
    }*/
  })
}

export default class MyEditor extends React.Component {
  constructor(props) {
    super(props);

    const contentState = customConvertFromHTML()(this.props.html);

    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link
      }
    ]);

    this.state = {
      editorState: this.props.html ? EditorState.createWithContent(contentState, decorator) : EditorState.createEmpty(decorator),
      links: [],
      html: this.props.html
    };
    this.onChange = (editorState) => {
      this.setState({editorState: editorState}, () => {
        this.setState({html: convertToHTML({
          styleToHTML: (style) => {
            if (style === 'BOLD') {
              return <span style={{fontWeight: 'bold'}} />;
            }
          },
          blockToHTML: (block) => {
            if (block.type === 'PARAGRAPH') {
              return <p />;
            } else if (block.type === 'H1') {
              return <h1 />;
            } else if (block.type === 'H2') {
              return <h2 />;
            } else if (block.type === 'H3') {
              return <h3 />;
            }
          },
          entityToHTML: (entity, originalText) => {
            let url = entity.data.url;
            if (url.indexOf('//') === -1) {
              url = '//' + url;
            }
            if (entity.type === 'LINK') {
              return <a href={url} target="_blank" rel="noopener noreferrer">{originalText}</a>;
            }
            return originalText;
          }
        })(this.state.editorState.getCurrentContent())
      }, () => {
        this.props.onChangeHTML('html', this.state.html);
      });
    });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.html !== this.state.html) {
      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link
        }
      ]);
      const contentState = customConvertFromHTML()(nextProps.html);
      this.setState({html: nextProps.html, editorState: EditorState.createWithContent(contentState, decorator)});
    }
  }

  _removeLink = (index) => {
    const {editorState} = this.state;
    const selection = editorState.getSelection();
    let newLinks = this.state.links.slice();
    newLinks.splice(index, 1);
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
        links: newLinks
      });
    }
  }

  _toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  _toggleLink = () => {
    const { editorState } = this.state;
    const selectionState = editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var contentState = editorState.getCurrentContent();
    var contentStateBlock = contentState.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    var selectedText = contentStateBlock.getText().slice(start, end);
    if (this.state.links.indexOf(selectedText) !== -1) {
      /* already a link */
      this._removeLink(this.state.links.indexOf(selectedText));
      return;
    }
    const contentStateWithLink = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: selectedText},
    );
    const entityKey = contentStateWithLink.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(this.state.editorState, { currentContent: contentStateWithLink });
    let newLinks = this.state.links.slice();
    newLinks.push(selectedText);
    this.onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    this.setState({links: newLinks});
  }

  _onButtonClick(style) {
    switch (style) {
      case BOLD:
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, BOLD));
        break;
      case ITALIC:
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, ITALIC));
        break;
      case UNDERLINE:
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, UNDERLINE));
        break;
      case LINK:
        this._toggleLink();
        break;
      case UNORDERED_LIST:
        this._toggleBlockType(UNORDERED_LIST);
        break;
      case ORDERED_LIST:
        this._toggleBlockType(ORDERED_LIST);
        break;
      case HEADER_ONE:
        this._toggleBlockType(HEADER_ONE);
        break;
      case HEADER_TWO:
        this._toggleBlockType(HEADER_TWO);
        break;
      case HEADER_THREE:
        this._toggleBlockType(HEADER_THREE);
        break;
      default:
        return false;
    }
  }

  customKeyBindingFn = (e) => {
    if (e.keyCode === 9) {  // Tab
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        1,  // maxDepth
      );
      if (newEditorState !== this.props.editorState) {
        this.onChange(newEditorState);
      }
      return false;
    }
    if (e.keyCode === 66 && hasCommandModifier(e)) {
      return BOLD;
    } else if (e.keyCode === 73 && hasCommandModifier(e)) {
      return ITALIC;
    } else if (e.keyCode === 85 && hasCommandModifier(e)) {
      return UNDERLINE;
    } else {
      return getDefaultKeyBinding(e);
    }
  }

  handleKeyCommand = (command) => {
    this._onButtonClick(command);
  }

  render() {
    return (
      <div className="editor-div" style={{display: "block"}}>
        <div className="clickable editor-buttons-div">
          <button onClick={this._onButtonClick.bind(this, BOLD)}><strong>B</strong></button>
          <button onClick={this._onButtonClick.bind(this, ITALIC)}><em>I</em></button>
          <button onClick={this._onButtonClick.bind(this, UNDERLINE)}><u>U</u></button>
          <button onClick={this._onButtonClick.bind(this, LINK)}><span role="img" aria-label="link">&#128279;</span></button>
          <button onClick={this._onButtonClick.bind(this, UNORDERED_LIST)}>ul</button>
          <button onClick={this._onButtonClick.bind(this, ORDERED_LIST)}>ol</button>
          <button onClick={this._onButtonClick.bind(this, HEADER_ONE)}>H1</button>
          <button onClick={this._onButtonClick.bind(this, HEADER_TWO)}>H2</button>
          <button onClick={this._onButtonClick.bind(this, HEADER_THREE)}>H3</button>
        </div>
        <div className="clickable padded-horizontal">
          <DraftEditor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            keyBindingFn={this.customKeyBindingFn}
          />
        </div>
      </div>
    );
  }
}

/*
To render HTML in admin view: <div dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
To render HTML in React Native: https://www.npmjs.com/package/react-native-render-html
*/

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link = (props) => {
 const {url} = props.contentState.getEntity(props.entityKey).getData();
 return (
   <a href={url} style={styles.link}>
     {props.children}
   </a>
 );
};

const styles = {
  root: {
    fontFamily: '\'Georgia\', serif',
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: '\'Georgia\', serif',
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};
